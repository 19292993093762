import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

import {
  AUTH_REQUEST,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
  FETCH_RESET_PASSWORD_DATA_REQUEST,
  FETCH_RESET_PASSWORD_DATA_SUCCESS,
  FETCH_RESET_PASSWORD_DATA_FAIL,
  CLEAR_SESSION,
  AUTH_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_REQUEST,
  SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_FAIL,
  SET_IS_FEDERATED_LOGIN,
  SET_GEO_LOCATION,
  FETCH_SSO_USER_VERIFY_REQUEST,
  FETCH_SSO_USER_VERIFY_SUCCESS,
  FETCH_SSO_USER_VERIFY_FAIL,
  FETCH_SSO_USER_VERIFY_RESET,
} from '../../redux/actionTypes/auth.js';
import {
  CHANGE_ADMIN_VIEW,
  ADD_ADMIN_USER,
} from '../../redux//action/actionTypes';
import useApi from '../../Hooks/useApi';
import ProceedMessageModal from '../../Components/Modal/ProceedMessageModal.js';
import ContactSupportModal from '../../Components/Modal/ContactSupportModal.js';
import messageConfigs from '../../Helper/PopupMessageConfig.js';
import DottedLoader from '../../Components/Loader/DottedLoader.js';
import useAuth from '../../Hooks/useAuth.js';
import { systemConfig } from '../../Common/SystemConstConfigs.js';
import { getCountryFromTimeZone } from '../../Helper/SystemManager.js';
import useFetchDataApi from '../../Hooks/useFetchDataApi';
import SsoVerifyModal from '../../Components/Modal/SsoVerifyModal.js';
import {
  UPDATE_LAST_ACTIVE_FAIL,
  UPDATE_LAST_ACTIVE_REQUEST,
  UPDATE_LAST_ACTIVE_SUCCESS,
} from '../../redux/actionTypes/createUser.js';

const {
  REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE,
  REACT_APP_CLIENT_ID,
  REACT_APP_IDENTITY_PROVIDER,
} = process.env;

const Login = ({ history, location }) => {
  const isLoginScreen = location.pathname !== '/reset/changePassword';
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginErrorModalOpen, setIsLoginErrorModalOpen] = useState(null);
  const [isContactSUpportModalOpen, setIsContactSUpportModalOpen] =
    useState(null);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [isPasswordTokenExpired, setIsPasswordTokenExpired] = useState(null);
  const [resetPasswordInfo, setResetPasswordInfo] = useState({
    token: '',
    userId: '',
    newPassword: '',
    confirmPassword: '',
    profileImage: '',
    userName: '',
    userEmail: '',
  });
  const [isSsoVerifyModalOpen, setIsSsoVerifyModalOpen] = useState(false);
  const dispatch = useDispatch();

  const signIn = useAuth();
  const [userDataRequest] = useApi();
  const [getResetPasswordUserData] = useApi();
  const [resetPasswordRequest] = useApi('/');
  const [syncFederatedLoginData] = useApi();
  const [updateLoginTime] = useApi();
  const [getS3bucketData] = useFetchDataApi();
  const [verifySsoUser] = useApi();
  const {
    access_token,
    userData,
    resetPasswordUserData,
    auth_error,
    authUserDataLoading,
    resetPasswordUserDataRequestLoading,
    resetPasswordUserDataError,
    themeInfo,
    federateLoginData,
    isFederateLogin,
    federatedLoginError,
    authUserDataError,
    ssoUserVerifyLoading,
    ssoUserVerified,
    ssoUserVerifyError,
  } = useSelector(state => state?.auth);

  const logo = themeInfo?.headerLogoUrl;
  const welcomeMsg = themeInfo?.welcomeText;
  const defaultProfileImageUrl = themeInfo?.defaultProfileImageUrl;
  const supportEmail = themeInfo?.supportEmail;
  let loginBackground = themeInfo?.loginBackgroundUrl;

  const {
    token,
    userId,
    newPassword,
    confirmPassword,
    profileImage,
    name,
    userEmail,
  } = resetPasswordInfo;

  // Changing site language according to backend language code
  useEffect(() => {
    if (themeInfo?.languageCode) {
      i18n.changeLanguage(themeInfo?.languageCode);
    }
  }, [themeInfo]);

  useEffect(() => {
    position();
    dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  }, []);

  const sendLastLoginTime = () => {
    updateLoginTime(
      '/user/updateUser',
      UPDATE_LAST_ACTIVE_REQUEST,
      UPDATE_LAST_ACTIVE_SUCCESS,
      UPDATE_LAST_ACTIVE_FAIL,
      null,
      null,
      'PUT',
      '',
      'isUserService'
    );
  };

  const position = async () => {
    const locationZoneData = getCountryFromTimeZone();
    await navigator.geolocation.getCurrentPosition(
      position => {
        dispatch({
          type: SET_GEO_LOCATION,
          payload: {
            ...locationZoneData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });
      },
      err => {
        dispatch({
          type: SET_GEO_LOCATION,
          payload: {
            ...locationZoneData,
            latitude: null,
            longitude: null,
          },
        });
      }
    );
  };

  useEffect(() => {
    if (resetPasswordUserData) {
      const { email, profilePicUrl, username } = resetPasswordUserData;

      setResetPasswordInfo({
        ...resetPasswordInfo,
        profileImage: profilePicUrl || defaultProfileImageUrl,
        name: username,
        userEmail: email,
      });
    }
  }, [resetPasswordUserData]);

  useEffect(() => {
    if (resetPasswordUserDataError) {
      setIsPasswordTokenExpired(
        messageConfigs.error.USER.RESET_PASSWORD_TOKEN_EXPRIRED
      );
    }
  }, [resetPasswordUserDataError]);

  useEffect(() => {
    if (!isLoginScreen) {
      const { token, userId } = qs.parse(location.search, {
        ignoreQueryPrefix: true,
        parseNumbers: true,
      });
      setResetPasswordInfo({ ...resetPasswordInfo, token, userId });
      getResetPasswordUserData(
        `/reset/getUserDataForResetPassword?userId=${userId}`,
        FETCH_RESET_PASSWORD_DATA_REQUEST,
        FETCH_RESET_PASSWORD_DATA_SUCCESS,
        FETCH_RESET_PASSWORD_DATA_FAIL,
        null,
        null,
        'GET',
        '',
        'isUserService'
      );
    }
  }, [isLoginScreen]);

  const fetchUserData = uuidUserName => {
    userDataRequest(
      `/user/getUserData?username=${uuidUserName}`,
      FETCH_USER_DATA_REQUEST,
      FETCH_USER_DATA_SUCCESS,
      FETCH_USER_DATA_FAIL,
      null,
      null,
      'GET',
      '',
      'isUserService'
    );
  };

  useEffect(() => {
    if (
      !isFederateLogin &&
      userData?.userName &&
      access_token &&
      isLoginScreen
    ) {
      sendLastLoginTime();
      fetchUserData(userData?.userName);
      getS3bucketData({ type: 'GET_S3_BUCKET_NAME' });
    }
  }, [access_token, userData]);

  useEffect(() => {
    if (authUserDataError && isLoginClicked) {
      setIsContactSUpportModalOpen(authUserDataError?.message);

      setIsLoginClicked(false);
    }
  }, [authUserDataError]);

  useEffect(() => {
    if (isFederateLogin && access_token && !federateLoginData) {
      syncFederatedLoginData(
        `/user/syncFederatedUserData`,
        SYNC_FEDERATE_LOGIN_DATA_REQUEST,
        SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
        SYNC_FEDERATE_LOGIN_DATA_FAIL,
        null,
        '',
        'PUT',
        '',
        'isUserService'
      );
    }
  }, [access_token]);

  useEffect(() => {
    if (isFederateLogin && access_token && federateLoginData?.username) {
      sendLastLoginTime();
      fetchUserData(federateLoginData?.username);
    }
  }, [access_token, federateLoginData]);

  useEffect(() => {
    if (userData) {
      const { userName, groupInfo, roleList } = userData;

      if (groupInfo?.length && access_token && isLoginScreen) {
        if (
          roleList &&
          roleList.length &&
          roleList.some(({ id }) => id === 3)
        ) {
          if (roleList.some(({ id }) => id === 1 || id === 2 || id === 5)) {
            dispatch({ type: ADD_ADMIN_USER, payload: true });
          }
          dispatch({ type: CHANGE_ADMIN_VIEW, payload: false });
          history.push('/home');
        } else {
          dispatch({ type: CHANGE_ADMIN_VIEW, payload: true });
          dispatch({ type: ADD_ADMIN_USER, payload: true });
          history.push('/node-tree');
        }
      } else if (
        access_token &&
        (!groupInfo || (groupInfo && groupInfo.length === 0)) &&
        roleList &&
        !roleList.length &&
        !authUserDataLoading
      ) {
        setIsLoginClicked(false);
        setIsContactSUpportModalOpen(
          messageConfigs.error.USER.GROUPS_NOT_ASSIGNED
        );
      }
    }
  }, [userData, access_token, authUserDataLoading]);

  useEffect(() => {
    if (auth_error) {
      setIsLoginClicked(false);

      // Suspended user message
      if (
        auth_error.code === systemConfig.errorcode.NotAuthorizedException &&
        auth_error.message === messageConfigs.error.USER.DISABLED
      ) {
        setIsContactSUpportModalOpen(messageConfigs.error.USER.SUSPENDED_USER);
        // Incorrect login creditials message
      } else if (
        auth_error.code === systemConfig.errorcode.NotAuthorizedException &&
        auth_error.message ===
          messageConfigs.error.USER.INCORRECT_USERNAME_PASSWORD
      ) {
        setIsLoginErrorModalOpen({
          isSuccess: false,
          message: messageConfigs.error.USER.INCORRECT_USERNAME_PASSWORD,
        });
        // Other login failures
      } else {
        setIsLoginErrorModalOpen({
          isSuccess: false,
          message: messageConfigs.error.TRY_LATER,
        });
      }

      dispatch({ type: AUTH_REQUEST });
    }
  }, [auth_error]);

  const onLogin = () => {
    if (userName && password) {
      setIsLoginClicked(true);
      signIn(userName, password);
    }
  };
  const handleResetPasswordInfo = (value, field) => {
    setIsPasswordTokenExpired(null);

    const tempResetPasswordInfo = { ...resetPasswordInfo };
    tempResetPasswordInfo[field] = value;
    setResetPasswordInfo(tempResetPasswordInfo);
  };

  const handleChangePassword = () => {
    if (
      confirmPassword &&
      newPassword &&
      confirmPassword === newPassword &&
      newPassword.length >= 8
    ) {
      resetPasswordRequest(
        `/reset/savePassword?token=${token}&newPassword=${newPassword}`,
        FETCH_RESET_PASSWORD_DATA_REQUEST,
        FETCH_RESET_PASSWORD_DATA_SUCCESS,
        FETCH_RESET_PASSWORD_DATA_FAIL,
        null,
        history,
        'POST',
        '',
        'isUserService'
      );
    }
  };

  useEffect(() => {
    if (!access_token) {
      getUser();
    }
  }, [access_token]);

  async function getUser() {
    await Auth.currentAuthenticatedUser()
      .then(userData => {
        Auth.currentCredentials().then(credential => {
          const { accessKeyId, secretAccessKey, sessionToken } = credential;
          dispatch({
            type: AUTH_SUCCESS,
            payload: {
              accessKeyId,
              secretAccessKey,
              sessionToken,
              userName: userData.getUsername(),
              preferredUsername: userData?.attributes?.preferred_username,
              refreshToken: userData
                .getSignInUserSession()
                .getRefreshToken()
                .getToken(),
              accessToken: userData
                .getSignInUserSession()
                .getAccessToken()
                .getJwtToken(),
            },
          });
        });
      })
      .catch();
  }

  const onClickSsoUserContinue = () => {
    dispatch({
      type: SET_IS_FEDERATED_LOGIN,
    });

    Auth.federatedSignIn({
      provider: REACT_APP_IDENTITY_PROVIDER,
    });
    setIsSsoVerifyModalOpen(false);
    dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
  };

  const onClickVerifySsoUser = email => {
    verifySsoUser(
      `/user/${email}/federatedLogin`,
      FETCH_SSO_USER_VERIFY_REQUEST,
      FETCH_SSO_USER_VERIFY_SUCCESS,
      FETCH_SSO_USER_VERIFY_FAIL,
      null,
      null,
      'GET',
      '',
      'isUserService'
    );
  };

  const renderResetPasswordModal = () => {
    return (
      <div className="loginscreen__resetpassword">
        <div className="loginscreen__resetpassword--page-title">
          <p>{t('Reset Password')}</p>
        </div>
        {resetPasswordUserDataRequestLoading ? (
          <DottedLoader />
        ) : (
          <div className="loginscreen__resetpassword--page-body">
            <div className="loginscreen__resetpassword--page-row">
              <div className="loginscreen__resetpassword--page-column">
                <img
                  src={profileImage || defaultProfileImageUrl}
                  alt="userimage"
                  className="loginscreen__resetpassword--page-userimage"
                  onError={event => {
                    event.target.onerror = null;
                    event.target.src = defaultProfileImageUrl;
                  }}
                />
              </div>
              <div className="loginscreen__resetpassword--page-column">
                <h4>{name}</h4>
                <p>{userEmail}</p>
              </div>
            </div>
            <div className="loginscreen__resetpassword--page-row password-field">
              {confirmPassword && confirmPassword !== newPassword && (
                <h3>
                  <span>{t('Warning')}:</span>
                  {messageConfigs.error.USER.PASSWORD_NOT_MATCH}
                </h3>
              )}
              {isPasswordTokenExpired && (
                <h3>
                  <span>{t('Warning')}:</span> {isPasswordTokenExpired}
                </h3>
              )}
              {newPassword && newPassword.length < 8 && (
                <h3>
                  <span>{t('Warning')}:</span>
                  {messageConfigs.error.USER.INVALID_PASSWORD}
                </h3>
              )}
              <label>{t('Password')}</label>
              <input
                id="resetpassword"
                type="password"
                name=""
                value={newPassword}
                onChange={e =>
                  handleResetPasswordInfo(e.target.value, 'newPassword')
                }
                autoComplete={false}
              />
              <button
                className="top-button"
                onClick={() => {
                  const x = document.getElementById('resetpassword');
                  if (x.type === 'password') {
                    x.type = 'text';
                  } else {
                    x.type = 'password';
                  }
                }}
              >
                {t('show/hide password')}
              </button>
            </div>
            <div className="loginscreen__resetpassword--page-row password-field">
              <label>{t('Confirm Password')}</label>
              <input
                id="confirmpassword"
                type="password"
                name=""
                value={confirmPassword}
                onChange={e =>
                  handleResetPasswordInfo(e.target.value, 'confirmPassword')
                }
              />
              <button
                onClick={() => {
                  const x = document.getElementById('confirmpassword');
                  if (x.type === 'password') {
                    x.type = 'text';
                  } else {
                    x.type = 'password';
                  }
                }}
              >
                {t(' show/hide password')}
              </button>
            </div>
            <div className="loginscreen__resetpassword--page-row">
              <button
                className="btn btn--primary"
                onClick={handleChangePassword}
              >
                {t('Reset Password')}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const Tabs = () => {
    const [ToggleState, setToggleState] = useState(1);

    const toggleTab = index => {
      setToggleState(index);
    };

    const getActiveClass = (index, className) =>
      ToggleState === index ? className : '';

    return (
      <div className="container">
        <ul className="tab-list">
          <li
            className={`tabs ${getActiveClass(1, 'active-tabs')}`}
            onClick={() => toggleTab(1)}
          >
            Member Platform
          </li>
          <li
            className={`tabs ${getActiveClass(2, 'active-tabs')}`}
            onClick={() => toggleTab(2)}
          >
            Athletes
          </li>
          <li
            className={`tabs ${getActiveClass(3, 'active-tabs')}`}
            onClick={() => toggleTab(3)}
          >
            Coaches & Managers
          </li>
          <li
            className={`tabs ${getActiveClass(4, 'active-tabs')}`}
            onClick={() => toggleTab(4)}
          >
            Clubs
          </li>
          <li
            className={`tabs ${getActiveClass(5, 'active-tabs')}`}
            onClick={() => toggleTab(5)}
          >
            Governing Bodies
          </li>
          <li
            className={`tabs ${getActiveClass(6, 'active-tabs')}`}
            onClick={() => toggleTab(6)}
          >
            Scouts & Agents
          </li>
          <li
            className={`tabs ${getActiveClass(7, 'active-tabs')}`}
            onClick={() => toggleTab(7)}
          >
            Contact Us
          </li>
        </ul>
        <div className="content-container">
          <div className={`content ${getActiveClass(1, 'active-content')}`}>
            <p>Welcome to our cutting-edge interactive online member platform, where athletes, coaches, sports clubs, governing bodies, scouts, and agents all receive their own personalised member channels, revolutionising the way that they showcase their talents and engage with the sports community.</p>
            <p>Athletes can upload videos and documents to showcase their skills, opening doors to potential opportunities and self-promotion like never before.</p>
            <p>Coaches and clubs can share training sessions and tactics, fostering collaboration and knowledge exchange.</p>
            <p>Governing bodies gain a powerful tool to identify and nurture talent, driving the growth of their respective sports.</p>
            <p>Scouts and agents can use the platform to discover rising stars, negotiate contracts, and facilitate seamless connections.</p>
            <p>The platform also encourages interaction among members, enabling sports professionals to engage with each other, discuss trends, and forge valuable partnerships.</p>
            <p>Embrace the power of our interactive online member platform and immerse yourself in an innovative, interconnected sports ecosystem, driving success and elevating the sporting experience for all involved.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(2, 'active-content')}`}>
            <p>Joining our sports ecosystem opens up a world of exceptional benefits for athletes at every stage of their journey.</p>
            <p>Our state-of-the-art interactive online member platform provides the perfect setting for honing skills and pushing boundaries, whilst our professional sports consultants offer personalised analysis, and coaching and mentoring services, to maximise your own personal performance potential.</p>
            <p>With access to cutting-edge technology, athletes can showcase their talents to Scouts and Agents, fine-tune their strategies. Beyond the physical aspects, the ThirdEYE ecosystem fosters a supportive and inspiring community, encouraging athletes to forge lifelong connections and to reach the very top of their game.</p>
            <p>From nurturing talent at the grassroots level to propelling athletes to the dizzy heights within their chosen sport, our interactive sports ecosystem offers a holistic approach that empowers athletes to reach their full potential and achieve their dreams in the world of sports.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              <a href='https://buy.stripe.com/fZedSS2SO38YdQ414b' className='buyButton'>Become A Member</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(3, 'active-content')}`}>
            <p>Welcome to our dynamic online interactive sports ecosystem, where coaches, head-coaches and managers alike will discover an array of unparalleled benefits to elevate their careers and impact the sporting world.</p>
            <p>As a member of the ThirdEYE ecosystem, our members gain access to our state-of-the-art interactive online member platform and cutting-edge sports technology, empowering you to optimise personal progression, training methodologies, team communication, to manage your players, and unlock your teams' full potential.</p>
            <p>Our growing community fosters collaboration and knowledge exchange, allowing coaches to learn from industry experts and share best practices.</p>
            <p>Additionally, our interactive platform offers extensive networking opportunities, connecting coaches with talented athletes, and the potential for career advancement.</p>
            <p>By joining our sports ecosystem, you embark on a transformative journey, where your expertise is honed, your influence expanded, and your impact magnified, ultimately shaping the future of sports on a local, national and international scale.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              <a href='https://buy.stripe.com/fZedSS2SO38YdQ414b' className='buyButton'>Become A Member</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(4, 'active-content')}`}>
            <p>Embrace a new era of growth and success by bringing your sports club into our online interactive sports ecosystem.</p>
            <p>As a member, your club gains access to our state-of-the-art online and interactive member platform, cutting-edge sports technology, legal services and expert coaching resources, providing your athletes with an unmatched platform for skill development and performance enhancement.</p>
            <p>Collaborate and compete within a supportive community of like-minded clubs, coaches, head coaches, scouts and agents, fostering healthy rivalries and creating lasting friendships.</p>
            <p>Our ecosystem offers a gateway to talent discovery, allowing you to recruit promising athletes and to strengthen your team. Additionally, the exposure to a wider audience amplifies your club's visibility and revenue potential.</p>
            <p>Embrace the power of the ThirdEYE sports ecosystem, where your club can thrive, achieve new heights, and leave an indelible mark on the sporting world.</p>
            
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              <a href='https://buy.stripe.com/4gwg100KG6la7rG6oq ' className='buyButton'>Become A Member</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(5, 'active-content')}`}>
            <p>We welcome sport's governing bodies to an online and interactive sports ecosystem that promises a myriad of benefits for your organisation and those that you represent.</p>
            <p>By joining our member platform, you gain access to coaching and mentoring from sports professional, a comprehensive database of talented athletes, streamlining talent identification, and nurturing programs to strengthen your sport at all levels.</p>
            <p>Our state-of-the-art interactive online member platform and expert consultant resources provides an ideal setting for talent development and training, ensuring a steady pipeline of competitive athletes.</p>
            <p>Collaborate with other governing bodies and clubs within our growing international community to foster unity and drive the growth of your sport.</p>
            <p>Our ecosystem's cutting-edge sports technology empowers you to make informed decisions, improve communication, and implement innovative strategies to elevate your sport to new heights.</p>
            <p>Embrace the opportunities of our sports ecosystem and let us embark on a journey together to shape the future of sports governance and secure a brighter, more competitive tomorrow.</p>
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              <a href='https://buy.stripe.com/4gwg100KG6la7rG6oq' className='buyButton'>Become A Member</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(6, 'active-content')}`}>
            <p>Step into a realm of endless opportunities as sports scouts and agents become an integral part of our online and interactive sports ecosystem. With access to our vast database of talented athletes, your ability to discover and represent promising prospects becomes unparalleled.</p>
            <p>Our state-of-the-art interactive online member platform and expert coaching and mentoring services enable you to provide personalised guidance and support to your clients, enhancing their potential for success.</p>
            <p>Collaborate with other professionals within our thriving community, expanding your network and creating mutually beneficial partnerships.</p>
            <p>Our ecosystem's cutting-edge sports technology empowers you to make informed decisions and negotiate advantageous contracts.</p>
            <p>By joining our sports ecosystem, you gain a competitive advantage, enabling you to excel in talent acquisition and representation, shaping the careers of athletes destined for greatness in the world of sports.</p>
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              <a href='https://buy.stripe.com/5kA2aa2SObFu13icMS' className='buyButton'>Become A Member</a>
            </div>
          </div>
          <div className={`content ${getActiveClass(7, 'active-content')}`}>
            <p>
              We strongly believe in a "get to know our members first" approach to how we conduct our business, and to this end we operate a Monday to Saturday video-call enquiry service that allows you to "get to know us", and for us to find out how we can best serve you, before you become a ThirdEYE Member.
            </p>
            <p>
              Book your <span><a href="https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call?month=2023-07">"DiscoveryCall"</a></span> here to arrange a date and time that
              best suits you for a no obligation chat.
            </p>
            <p>
              In the unlikely event that our "DiscoveryCall" facility is
              unavailable, please email <span><a href = "mailto:thirdeye@koisports.co.uk">ThirdEYE@koisports.co.uk</a></span>{' '}
              for assistance.
            </p>
            <div className="loginscreen__membership-wrapper">
              <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLoginModal = () => {
    const renderLoginLogo = () => {
      switch (process.env.REACT_APP_LOGIN_PAGE) {
        case 'TYPE-1':
          return (
            <div className="loginscreen__logo">
              <img
                src={
                  'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/eye_logo.png'
                }
                alt="logo-eye"
                className="eye--logo"
              />
            </div>
          );

        default:
          return (
            <img
              src={
                'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/eye_logo.png'
              }
              alt="logo-eye"
              className="eye--logo"
            />
          );
      }
    };

    return (
      <>
        {renderLoginLogo()}
        <div className="loginscreen__container-wrapper">
          <div className="loginscreen__section-one">
            <div className="loginscreen__section-one--left">
              <h3>
                SIGN UP TODAY FOR FREE AND EXPLORE THE POWER OF THE THIRDEYE SPORTS ECOSYSTEM!
              </h3>
              <p>Welcome to our online and interactive sports ecosystem, where passion, competition, and camaraderie come together to create an unparalleled experience designed specifically for athletes, coaches, head-coaches, managers, sports clubs, club owners, governing bodies, scouts and agents.</p>
              <p>At ThirdEYE we pride ourselves on providing a comprehensive membership platform that encompasses all aspects of the sporting world, from grassroots through to professional sport.</p>
              <p>With our state-of-the-art interactive online membership platform, expert performance analysis, coaching and mentoring services from professional sports consultants, and cutting-edge sports technology, we foster the growth and development of sport on an international scale, nurturing talent and delivering member progression opportunity pathways.</p>
              <p>Whether you're an athlete, coach, head-coach, manager, sports club, club owner, governing body, scout or agent, our sports ecosystem promises unforgettable moments, inspiring stories, and a sense of community that transcends boundaries.</p>
              <p>Sign up today for free and experience the exceptional world of the ThirdEYE International Sports Consultancy.</p>
              <div className="loginscreen__section-one--buttons">
                <a href='https://www.canva.com/design/DAFdJqNE-LE/A5rlJrydkh8LJI-fc_7LBg/view?utm_content=DAFdJqNE-LE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink' className='discoveryButton'>Download the Membership eBrochure</a>
                <a href='https://calendly.com/david-johnson-rayner/third-eye-sports-discovery-call' className='discoveryButton'>Book a Discovery Call</a>
              </div>
              <div className="loginscreen__bradning">
                <img
                  src={
                    'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/koach_power.png'
                  }
                  alt="logo-koach"
                  className="koach--logo"
                />
                <p>Version 2.0</p>
              </div>
            </div>
            <div className="loginscreen__section-one--right loginscreen__form form">
              <h4>
                Sign-up here for FREE to explore our new sports ecosystem.
Meet our elite sport consultants, access sports analysis and club communication technology, learn more about how owning your own ThirdEYE video channel can help you progress your sports career through global representation and Member Showcase talent ID video reels, and receive updates on discounts, new services and member benefits.
              </h4>
              <div className="form__form--fields">
                <div className="form--field">
                  <label>{t('Username or Email Address')}</label>
                  <input
                    className="form-input"
                    value={userName}
                    onChange={e => setUserName(e.target.value.trim())}
                  />
                </div>
                <div className="form--field password-field">
                  <label>{t('Password')}</label>
                  <input
                    id="password"
                    className="form-input"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <button
                    onClick={() => {
                      const x = document.getElementById('password');
                      if (x.type === 'password') {
                        x.type = 'text';
                      } else {
                        x.type = 'password';
                      }
                    }}
                  >
                    {t('show/hide password')}
                  </button>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push('/forgotPassword')}
                  >
                    Forgot your password?
                  </a>
                </div>
                {/* <div className="form--field last">
                <input
                  className="form-input--checkbox"
                  type="checkbox"
                  id="remember-me"
                  name="remember-me"
                  value="Bike"
                />
                <label for="remember-me">Remember Me</label>
              </div> */}
                <div className="form--buttons">
                  <div className='social--wrapper'>
                    <a href='https://www.instagram.com/thirdeyesports/'>
                      <img
                        src={
                          'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/instagram.png'
                        }
                        alt="instagram icon"
                        className="igIcon"
                      />
                    </a>
                    <a href='https://twitter.com/ThirdEYESport'>
                      <img
                        src={
                          'https://koach-thirdeye-contents.s3.eu-west-1.amazonaws.com/theme-images/twitter.png'
                        }
                        alt="twitter icon"
                        className="twitterIcon"
                      />
                    </a>
                  </div>
                  <div>
                    <button
                      className={`
                      btn btn--primary
                      ${isLoginClicked ? 'btn--loader' : ''}
                    `}
                      onClick={onLogin}
                    >
                      {t('Login')}
                    </button>
                    <button
                      className="btn btn--primary"
                      onClick={() => history.push('/signup')}
                    >
                      {t('Sign Up')}
                    </button>
                  </div>
                </div>
                {REACT_APP_IS_FEDETRATE_LOGIN_AVAILABLE && (
                  <>
                    <div className="form--field text">Or</div>

                    <button
                      className="btn btn--primary form--field federate-sign-button"
                      onClick={() => setIsSsoVerifyModalOpen(true)}
                    >
                      Sign in with your {REACT_APP_CLIENT_ID} account
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="loginscreen"
        style={{
          backgroundImage: `url(${loginBackground})`,
        }}
      >
        <div className="container">
          {/* Add class "password" to wrapper on reset password */}
          <div className="loginscreen__wrapper">
            {isLoginScreen ? renderLoginModal() : renderResetPasswordModal()}
          </div>
        </div>
        {isLoginErrorModalOpen && (
          <ProceedMessageModal
            isSuccess={isLoginErrorModalOpen.isSuccess}
            message={isLoginErrorModalOpen.message}
            handleClose={() => setIsLoginErrorModalOpen(null)}
            handleSuccess={() => setIsLoginErrorModalOpen(null)}
            handleError={() => setIsLoginErrorModalOpen(null)}
          />
        )}
        {isContactSUpportModalOpen && (
          <ContactSupportModal
            message={isContactSUpportModalOpen}
            supportEmail={supportEmail}
            handleClose={() => {
              setIsContactSUpportModalOpen(null);
              Auth.signOut();
              dispatch({
                type: CLEAR_SESSION,
              });
            }}
          />
        )}
        {federatedLoginError && (
          <ContactSupportModal
            message={messageConfigs.error.USER.FEDERATE_LOGIN_FAIL}
            supportEmail={supportEmail}
            handleClose={() => {
              Auth.signOut();
              setIsContactSUpportModalOpen(null);
              dispatch({
                type: CLEAR_SESSION,
              });
            }}
          />
        )}
        {isSsoVerifyModalOpen && (
          <SsoVerifyModal
            handleClose={() => {
              setIsSsoVerifyModalOpen(false);
              dispatch({ type: FETCH_SSO_USER_VERIFY_RESET });
            }}
            onClickVerifySsoUser={email => onClickVerifySsoUser(email)}
            onClickContinueSsoUser={onClickSsoUserContinue}
          />
        )}
      </div>
      <div className="loginscreen__section-two">
        <Tabs />
      </div>
    </>
  );
};

export default Login;
